<template>
  <v-container class="ma-0 mb-4 pa-0">
    <div class="display-1 textDark--text text-capitalize" v-if="title">{{title}}</div>
    <div v-else class="mt-12"></div>
    <v-breadcrumbs v-if="showBreadcrumbs" class="bread-crumbs pl-0" primary :items="links" small>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.to" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
export default {
  name: "AdminHeader",
  props: {
    title: {
      type: String,
      default: ""
    },
    links: {
      type: Array
    }
  },
  computed: {
    showBreadcrumbs() {
      return this.links && this.links.length > 1;
    }
  }
};
</script>
