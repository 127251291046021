<template>
  <v-navigation-drawer
    class="primaryLight"
    :mini-variant="drawer"
    v-model="navDrawer"
    permanent
    app
  >
    <v-list-item class="pt-12 pb-4 primaryDark d-flex flex-column">
      <v-list-item-avatar tile :class="avatarSelfAlignment">
        <div
          v-if="avatar == ''"
          :class="avatarSelfAlignment"
          class="avatar primary textLight--text"
        >
          <label>{{currentUser.firstName.charAt(0)}}</label>
          {{currentUser.lastName.charAt(0)}}
        </div>
        <v-img v-else :src="avatar" aspect-ratio="1"></v-img>
      </v-list-item-avatar>
      <v-list-item-title
        class="secondary--text align-self-start"
      >{{currentUser.firstName}} {{currentUser.lastName}}</v-list-item-title>
      <v-list-item-subtitle style="color:#C0C0C0;" class="align-self-start">{{jobTitle}}</v-list-item-subtitle>
    </v-list-item>
    <v-list flat dark class="ma-0 pa-0 primaryDark">
      <v-list-item v-if="editProfile" :to="{ name: 'user-home'}" class="d-flex flex-row">
        <v-list-item-icon>
          <v-icon class="list-item">mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="body-1 font-weight-regular textLight--text">Your Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />

    <v-list flat class="pt-6 pl-0">
      <v-list-item-group mandatory dark color="primaryText" v-model="selectedItem">
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="item.type === 'item'"
            :key="index"
            :exact="exactLink"
            :to="{ name: item.link }"
            :class="!exactLink ? (highlightTitle == item.title ? 'black--text': 'textLight--text') : ''"
          >
            <v-list-item-icon>
              <v-icon v-if="item.icon.includes('mdi')" class="list-item">{{ item.icon }}</v-icon>
              <svgicon v-else-if="item.icon" height="18" color="white" :icon="item.icon"></svgicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="my-12" v-else-if="item.type === 'divider'" :key="index">
            <v-divider />
          </div>
          <v-list-item
            v-if="item.type === 'beez-in'"
            :key="index"
            flat
            :to="{name : item.link, query: {
              beezIntoModule: 'Elevation'
            }}"
            class="textLight--text"
          >
            <v-list-item-icon>
              <v-icon class="list-item">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <v-footer color="primary" class="ma-0 pa-0" absolute>
      <v-list v-if="!drawer" class="pa-0" flat style="width:100%;" dark>
        <v-divider />

        <v-list-item @click="logoutEvent" color="primaryText">
          <v-list-item-icon>
            <v-icon small>mdi-arrow-right-bold-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2 font-weight-regular">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled style="background-color: #0f8dab;">
          <div class="d-flex flex-row justify-center align-center">
            <p class="textLight--text body-1 font-weight-regular ma-0 pa-0">Powered by</p>
            <v-divider vertical class="mx-6" color="white" />
            <v-img max-height="30" max-width="65" src="../../assets/beez_social_erp.svg"></v-img>
          </div>
        </v-list-item>
      </v-list>
      <v-list v-else class="pa-0" flat style="width:100%;" dark>
        <v-list-item disabled style="background-color: #0f8dab;">
          <v-img max-width="25" class="ma-0 pa-0" src="../../assets/beez_small.svg"></v-img>
        </v-list-item>
      </v-list>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "SideBar",
  props: [
    "drawer",
    "items",
    "avatar",
    "jobTitle",
    "editProfile",
    "exactLink",
    "highlightTitle"
  ],
  data() {
    return {
      navDrawer: false,
      mini: false,
      selectedItem: 0
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    avatarSelfAlignment() {
      return this.drawer ? "align-self-center" : "align-self-start";
    }
  },
  created() {},
  methods: {
    logoutEvent: function() {
      this.$emit("logoutEvent");
    }
    // beezIntoModule: function() {
    //   this.$emit("beezIntoModule");
    // }
  }
};
</script>

<style>
/*
.highlighted .list-item {
  color: black;
}
.deactivate .list-item {
  color: white;
} */
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  border-radius: 6px;
}
</style>
