<template>
  <v-app-bar flat app color="secondary" class="py-2">
    <v-app-bar-nav-icon class="grey--text" @click="toggleSideBar"></v-app-bar-nav-icon>
    <v-img max-height="30" max-width="30" class="ma-0 pa-0" src="../../assets/beez_small.svg"></v-img>

    <v-spacer></v-spacer>

    <slot name="right"></slot>

    <div v-if="admin" class="ma-0 pa-0 d-flex align-center">
      <v-img
        v-if="currentAccount && currentAccount.logo"
        width="43"
        height="43"
        :src="currentAccount.logo.url"
      ></v-img>
      <v-btn height="43" class="primaryLight flat ml-4 text-capitalize" dark>
        <span class="subtitle-1">{{ currentAccount.name }}</span>
        <v-icon right>mdi-menu-down</v-icon>
      </v-btn>
    </div>
    
  </v-app-bar>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "AppBar",

  props: {
    drawer: {
      type: Boolean,
      required: true
    },
    admin: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    toggleSideBar: function() {
      this.$emit("toggleSideBar");
    }
  },
  computed: {
    ...mapFields("auth", ["currentAccount"])
  }
  // watch: {
  //   currentAccount: {
  //     deep: true,
  //     immediate: true,
  //     handler(value){
  //       this.account.accountName = value.name;
  //       if (value.account.logo){
  //         this.account.imageSrc = value.logo.url;
  //       }
  //     }
  //   }
  // }
};
</script>
